// eslint-disable-next-line no-restricted-imports
import logger from "loglevel";
import { env } from "@/env";
import { prefixLogger } from "./prefix";

const level = env.VITE_LOG_LEVEL;
logger.setDefaultLevel(level);
logger.setLevel(level);

prefixLogger(logger);

export { logger };
