import { env } from "@/env";

export const COLOR_SCHEMES = ["light", "dark"] as const;

export const VERIFICATION_CODE_LENGTH = 6;
export const VERIFICATION_CODE_RESEND_TIMEOUT_IN_SECONDS = env.DEV ? 5 : 30;

export const MATCH_PREFERS_COLOR_SCHEME_DARK = "(prefers-color-scheme: dark)";

export const PAGE_PRIVACY_POLICY_URL = "https://imperfect.so/legal/privacy";
export const PAGE_AI_POLICY_URL = "https://imperfect.so/legal/ai";
export const PAGE_TERMS_AND_CONDITIONS_URL = "https://imperfect.so/legal/terms";

export const REFLECTION_TARGET = 10;
export const FRIEND_CHECK_TARGET = 3;
