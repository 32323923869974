import { createApi } from "@/lib/backend";
import { ContentType } from "@/lib/backend/api";
import { useAuthStore } from "@/lib/store/auth";
import { logger } from "@/logger";

export const fetch: typeof window.fetch = async (input, init) => {
	if (!init?.headers) {
		return await fetch(input as never, init);
	}

	const response = await window.fetch(input as never, init);

	const url = new URL(String(input));

	if (
		response.ok ||
		response.status !== 401 ||
		url.pathname.startsWith("/auth") ||
		url.pathname.startsWith("/friendcheck/public")
	) {
		return response;
	}

	const fetchLogger = logger.getLogger("fetch");

	try {
		const refreshToken = useAuthStore.getState().refreshToken;

		fetchLogger.trace({ refreshToken }, "Refresh Token");

		const refreshResponse = await createApi().auth.authControllerRefresh({
			type: ContentType.Text,
			format: "text",
			headers: { Authorization: `Bearer ${refreshToken}` },
		});

		if (!refreshResponse.ok) {
			fetchLogger.error(refreshResponse, "Refresh Token Invalid");
			throw new Error("Refresh token is invalid");
		}

		fetchLogger.trace(refreshResponse, "Refresh Token Valid");

		const accessToken = refreshResponse.data;

		useAuthStore.getState().authenticate({ accessToken, refreshToken });

		// @ts-expect-error Not typed correctly
		init.headers.Authorization = `Bearer ${accessToken}`;

		return await fetch(input as never, init);
	} catch (error) {
		fetchLogger.error({ error }, "Refresh Token Error");
		useAuthStore.getState().unauthenticate();
		return response;
	}
};
