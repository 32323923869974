import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonPage,
	IonRadio,
	IonRadioGroup,
	useIonRouter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { z } from "zod";
import SplashIcon from "@/assets/splash_icon.svg";
import { FormTextarea } from "@/components/Form/Textarea";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type FriendCheckQuestionTree, type SubmitFriendcheckDto } from "@/lib/backend/api";
import { useExtractedMutation, useExtractedQuery } from "@/lib/backend/utils";
import { useKeyboard } from "@/lib/hooks/useKeyboard";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";
import { friendCheckSelectors, useFriendCheckStore } from "@/lib/store/friend-check";
import { cx } from "@/lib/style/cva.config";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function SharedFriendCheckQuestions() {
	const { t, i18n } = useTranslation();
	const router = useIonRouter();
	const [currentIndex, setCurrentIndex] = useState(0);
	const PreferredHaptics = usePreferredHaptics();
	const state = useFriendCheckStore(friendCheckSelectors.state);
	const { isOpen } = useKeyboard();

	useEffect(() => {
		if (!state.friendId || !state.friendHash) {
			router.push(Routes.App(), "none", "replace");
		}
	}, [state]);

	const query = useExtractedQuery({
		queryKey: ["friend-check", state.friendId, state.friendHash] as const,
		fn: (context) =>
			backendService.friendcheck.friendcheckControllerGetPublicFriendCheck(
				context.queryKey[1],
				context.queryKey[2],
				{ signal: context.signal },
			),
		enabled: !!state.friendId && !!state.friendHash,
	});
	const friendCheck = query.data;

	function flattenQuestions(
		question: FriendCheckQuestionTree | null,
	): Array<FriendCheckQuestionTree & { initialQuestion: FriendCheckQuestionTree }> {
		if (!question || !question.followUpQuestion) {
			return [];
		}

		return [
			{ ...question.followUpQuestion, initialQuestion: question },
			...flattenQuestions(question.followUpQuestion),
		];
	}

	const questions =
		friendCheck?.questions?.flatMap<
			FriendCheckQuestionTree & { initialQuestion?: FriendCheckQuestionTree }
		>((question) => [question, ...flattenQuestions(question)]) ?? [];

	const mutation = useExtractedMutation({
		mutationKey: ["friend-check", "create"],
		fn: ({
			friendId,
			friendHash,
			...data
		}: { friendId: string; friendHash: string } & SubmitFriendcheckDto) =>
			backendService.friendcheck.friendcheckControllerPostPublicFriendCheck(
				friendId,
				friendHash,
				data,
			),
	});

	const isLast = !friendCheck ? false : currentIndex >= questions.length - 1;

	function getTextarea(index: number) {
		return document.querySelector(
			`[data-question-index='${index}']`,
		) as HTMLIonTextareaElement | null;
	}

	const form = useZodForm<{ answers: string[] }>({
		defaultValues: { answers: [] },
		onSubmit: ({ value }) => {
			if (!state.submitted) {
				mutation.mutate(
					{
						friendId: state.friendId,
						friendHash: state.friendHash,
						name: state.name,
						relation: state.relation,
						email: state.acceptsEmail ? state.email : undefined,
						questions: questions.map((question, index) => ({
							questionId: question.id,
							answer: value.answers[index],
						})),
					},
					{
						onSuccess: (data) => {
							useFriendCheckStore.setState((state) => {
								state.submitted = true;
								state.result = data;
							});
							router.push(Routes.SharedFriendCheckThankYou());
						},
					},
				);
			} else {
				router.push(Routes.SharedFriendCheckThankYou());
			}
		},
	});

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<div
						slot="start"
						className="flex min-w-0 items-center justify-start gap-3 plt-native:hidden"
					>
						<img
							src={SplashIcon}
							className="-mx-2 -mb-4 -mt-[1.125rem] size-12 min-h-12 min-w-12 select-none drag-none"
						/>
						<span className="truncate text-start text-base font-semibold text-brown-700">
							{t("seo.title")}
						</span>
					</div>
					<IonButtons
						slot="start"
						onClick={() => PreferredHaptics.impact()}
						className="hidden plt-native:block"
					>
						<IonBackButton
							defaultHref={Routes.App()}
							color="light"
							className="touch-target min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>

			<Content scrollY={true}>
				<form
					className="flex flex-1 flex-col gap-4"
					style={{ minHeight: "calc(calc(100vh - var(--offset-top)) - 200px)" }}
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateField(`answers[${currentIndex}]`, "submit");
						void form.handleSubmit();
					}}
				>
					<div className="relative isolate flex-1">
						{questions.map((question, index, self) => (
							<motion.div
								key={question.id}
								className="absolute inset-0 flex flex-col gap-4 rounded-2xl bg-white p-3"
								initial={
									index === currentIndex ? "current" : index > currentIndex ? "next" : "previous"
								}
								animate={
									index === currentIndex ? "current" : index > currentIndex ? "next" : "previous"
								}
								variants={{
									current: {
										opacity: 1,
										x: 0,
										scale: 1,
										rotate: 0,
										zIndex: 0,
									},
									previous: {
										opacity: 0,
										x: "-75%",
										scale: 0.75,
										rotate: -3,
										zIndex: -1,
									},
									next: {
										opacity: 0,
										x: "75%",
										scale: 0.75,
										rotate: 3,
										zIndex: 1,
									},
								}}
								transition={{ type: "spring", bounce: 0.2, duration: 0.4 }}
							>
								<div className="flex flex-col gap-4">
									<InitialQuestion
										question={question.initialQuestion}
										questions={self}
										answers={form.getFieldValue("answers")}
									/>

									<strong className="text-pretty text-base font-semibold text-brown-600">
										{question.question}
									</strong>
								</div>

								{(function () {
									switch (question.questionType) {
										case "MULTIPLE_CHOICE":
											return (
												<form.Field
													name={`answers[${index}]`}
													validators={{
														onSubmit: z
															.array(z.string())
															.min(1, t("fields.options.errors.min", { count: 1 })),
													}}
													children={(field) => (
														<IonRadioGroup
															name={field.name}
															value={field.state.value}
															onIonChange={(event) =>
																field.handleChange(String(event.detail.value))
															}
															onBlur={field.handleBlur}
															className={cx(
																"flex flex-col gap-2",
																field.state.meta.isTouched && "ion-touched",
																!!field.state.meta.errors &&
																	field.state.meta.errors.length > 0 &&
																	"ion-invalid",
															)}
														>
															{question.options.map((option, index) => (
																<IonRadio
																	key={index}
																	value={option}
																	labelPlacement="end"
																	justify="start"
																	disabled={
																		field.state.meta.isValidating || field.form.state.isSubmitting
																	}
																	className="rounded-xl bg-brown-200 p-3 text-base font-normal text-brown-700"
																>
																	{option}
																</IonRadio>
															))}
															{!!field.state.meta.errors && !!field.state.meta.errors.length && (
																<span className="text-sm font-semibold text-danger-500">
																	{getErrorText({
																		language: i18n.language,
																		errors: field.state.meta.errors,
																	})}
																</span>
															)}
														</IonRadioGroup>
													)}
												/>
											);
										case "NUMBER":
										case "TEXT":
										case "EMAIL":
										case "DATA":
										default:
											return (
												<form.Field
													name={`answers[${index}]`}
													validators={{
														onSubmit: z
															.string({
																message: t("fields.answer.errors.min", { count: 3 }),
															})
															.min(3, t("fields.answer.errors.min", { count: 3 })),
													}}
													children={(field) => (
														<FormTextarea
															data-question-index={index}
															field={field}
															placeholder={t("fields.answer.placeholder")}
															inputMode="text"
															autoCapitalize="on"
															onKeyDown={async (event) => {
																if (event.metaKey && event.code === "Enter") {
																	field.handleChange(
																		String(
																			(event.target as unknown as { value: string })?.value ?? "",
																		),
																	);

																	if (index === self.length - 1) {
																		void form.handleSubmit();
																		return;
																	}

																	const result = await field.validate("submit");

																	if (result.length === 0) {
																		setCurrentIndex((current) => ++current);
																		getTextarea(index + 1)?.setFocus();
																	}
																}
															}}
															className="flex h-full flex-1 flex-col text-brown-700 [&:not(.ion-invalid)_.native-wrapper]:!border-brown-200"
														/>
													)}
												/>
											);
									}
								})()}
							</motion.div>
						))}
					</div>

					<div data-keyboard={isOpen} className="flex flex-col items-center gap-4 overflow-hidden">
						<div className="flex w-full items-center justify-between gap-4 *:flex-1 *:rounded-lg *:text-lg *:font-bold">
							<Button
								disabled={currentIndex === 0}
								onClick={() => {
									if (currentIndex > 0) {
										setCurrentIndex((current) => --current);
										getTextarea(currentIndex - 1)?.setFocus();
									} else {
										if (router.canGoBack()) {
											router.goBack();
										} else {
											router.push(Routes.SharedFriendCheck());
										}
									}
								}}
								className="text-brown-700 ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100"
							>
								<motion.span initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }}>
									{t("previous")}
								</motion.span>
							</Button>
							<form.Subscribe
								selector={(state) => [state.isSubmitting]}
								children={([isSubmitting]) => (
									<Button
										type={isLast ? "submit" : "button"}
										className="relative *:absolute"
										disabled={isSubmitting || mutation.isPending}
										onClick={
											!isLast
												? async () => {
														const result = await form.validateField(
															`answers[${currentIndex}]`,
															"submit",
														);

														if (result.length === 0) {
															setCurrentIndex((current) => ++current);
															getTextarea(currentIndex + 1)?.setFocus();
														}
													}
												: undefined
										}
									>
										<AnimatePresence>
											{!isLast ? (
												<motion.span
													key="next"
													initial={{ opacity: 0, scale: 0 }}
													animate={{ opacity: 1, scale: 1 }}
													exit={{ opacity: 0, scale: 0 }}
												>
													{t("next")}
												</motion.span>
											) : (
												<motion.span
													key="send"
													initial={{ opacity: 0, scale: 0 }}
													animate={{ opacity: 1, scale: 1 }}
													exit={{ opacity: 0, scale: 0 }}
												>
													{t("send")}
												</motion.span>
											)}
										</AnimatePresence>
									</Button>
								)}
							/>
						</div>

						<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full">
							{Array.from({ length: questions.length || 3 }).map((_, index) => (
								<div key={index} className="bg-brown-300" />
							))}
							<motion.div
								className="pointer-events-none absolute left-0 z-10 bg-brown-500"
								initial={{ x: "0rem" }}
								animate={{ x: `${currentIndex * 1.125}rem` }}
								transition={{ type: "string", duration: 0.3 }}
							/>
						</div>
					</div>
				</form>
			</Content>
		</IonPage>
	);
}

function InitialQuestion({
	question,
	questions,
	answers,
	...props
}: React.ComponentPropsWithoutRef<"div"> & {
	question?: FriendCheckQuestionTree & { initialQuestion?: FriendCheckQuestionTree };
	questions: FriendCheckQuestionTree[];
	answers: string[];
}) {
	const currentAnswer = answers[questions.findIndex((q) => q.id === question?.id)];

	if (!question) {
		return null;
	}

	return (
		<div {...props} className={cx("flex flex-col gap-2", props.className)}>
			<InitialQuestion
				question={question.initialQuestion}
				questions={questions}
				answers={answers}
			/>
			<div className="flex flex-col rounded border border-brown-200 bg-brown-100 p-2">
				<small className="text-pretty text-sm font-semibold">{question.question}</small>
				{!!currentAnswer && (
					<p data-ph-no-capture className="line-clamp-6 text-xs">
						{currentAnswer}
					</p>
				)}
			</div>
		</div>
	);
}
