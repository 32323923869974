import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRegisterSW } from "virtual:pwa-register/react";
import { debugValue } from "@/env/utils";
import { Dialog } from "@/lib/capacitor/Dialog";
import { PostHogProvider } from "@/lib/posthog/components/Provider";
import { ConfigProvider } from "@/lib/posthog/config";
import { QueryProvider } from "@/lib/query/Provider";
import { useCoreValues } from "@/lib/query/functions/core-values/all";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMe } from "@/lib/query/functions/user/me";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { SentryProvider } from "@/lib/sentry/Provider";
import { StoreProvider } from "@/lib/store/Provider";
import { Compose } from "@/utils/components/Compose";

export function Providers(props: React.PropsWithChildren) {
	const { t } = useTranslation();

	const { updateServiceWorker } = useRegisterSW({
		immediate: true,
		onNeedRefresh,
	});

	function onNeedRefresh() {
		Dialog.confirm({
			title: t("pwa.on-need-refresh.title"),
			message: t("pwa.on-need-refresh.message"),
			okButtonTitle: t("pwa.on-need-refresh.ok"),
			cancelButtonTitle: t("pwa.on-need-refresh.cancel"),
		}).then((result) => {
			if (!result) {
				return;
			}
			updateServiceWorker();
		});
	}

	return (
		<Compose
			providers={[
				<QueryProvider key="react-query" />,
				<StoreProvider key="zustand" />,
				<SentryProvider key="sentry" />,
				<PostHogProvider key="posthog" />,
				<ConfigProvider key="config" />,
				<DevelopmentProvider key="development" />,
				<DataProvider key="data" />,
			]}
			{...props}
		/>
	);
}

function DevelopmentProvider(props: React.PropsWithChildren) {
	const className = "debug_ui_touch-target";

	useEffect(() => {
		if (debugValue("ui:touch-target")) {
			document.documentElement.classList.add(className);
		} else {
			document.documentElement.classList.remove(className);
		}

		return () => {
			document.documentElement.classList.remove(className);
		};
	}, []);

	return <>{props.children}</>;
}

const DataProvider = memo(function DataProvider(props: React.PropsWithChildren) {
	useMe();
	useCoreValues();
	useMyProgression();
	useMyData();

	return <>{props.children}</>;
});
