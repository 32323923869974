import { env } from "@/env";
import { Capacitor } from "@/lib/capacitor";

export function getBasePath(
	path: string,
	options: { withScheme?: boolean } = { withScheme: true },
) {
	switch (Capacitor.Platform) {
		case "ios":
		case "android":
			if (options.withScheme) {
				return `imperfect://${path}`;
			}
			return `${env.VITE_HOST_URL}${path}`;

		case "web":
		default:
			return `${window.location.origin}${path}`;
	}
}
