import { useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";

export function PostHogPageView() {
	const router = useIonRouter();
	const postHog = usePostHog();

	// Track pageviews
	useEffect(() => {
		if (router.routeInfo?.pathname && postHog) {
			let url = window.origin + router.routeInfo.pathname;
			if (router.routeInfo?.search) {
				url = url + `?${router.routeInfo.search}`;
			}
			postHog.capture("$pageview", {
				$current_url: url,
			});
		}
	}, [router.routeInfo, postHog]);

	return null;
}
