import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonFooter,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
	useIonRouter,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { FormInput } from "@/components/Form/Input";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionChatBubble } from "@/components/Reflection/Chat/Bubble";
import { backendService } from "@/lib/backend";
import { type RefineCoreValueDto } from "@/lib/backend/api";
import { extractionWrapper } from "@/lib/backend/utils";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { paperAirplaneIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMutationStream } from "@/lib/query/hooks/useMutationStream";
import { Routes } from "@/lib/router";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function CoreValuesChat() {
	const params = Routes.CoreValuesChat.useParams();
	const messagesEnd = useRef<HTMLDivElement | null>(null);
	const router = useIonRouter();
	const { t } = useTranslation();

	const queryChosenCoreValue = useQuery({
		queryKey: ["chosen-core-value", params.id!] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetChosenCoreValue(context.queryKey[1], {
					signal: context.signal,
				}),
			),
		enabled: !!params.id,
	});

	useEffect(() => {
		if (
			router.routeInfo.pathname === Routes.CoreValuesChat() &&
			(!params.id || (!queryChosenCoreValue.isLoading && !queryChosenCoreValue.data))
		) {
			if (router.canGoBack()) {
				router.goBack();
			} else {
				router.push(Routes.Reflection(), "root", "replace");
			}
		}
	}, [params.id, queryChosenCoreValue.isLoading, queryChosenCoreValue.data]);

	const id = params.id!;

	const [question, setQuestion] = useState<string | null>(null);
	const [showStreamMessages, setShowStreamMessages] = useState(false);

	const coreValue = queryChosenCoreValue.data;

	const [streamedResponse, mutationStream] = useMutationStream({
		mutationKey: ["chosen-core-value", "message"],
		fn: (params, variables: RefineCoreValueDto) =>
			backendService.corevalues.coreValuesControllerRefineCoreValue(variables, params),
		onSettled: async () => {
			await queryChosenCoreValue.refetch();
			setShowStreamMessages(false);
		},
	});

	useEffect(() => {
		if (coreValue?.messages.length == 0 && mutationStream.isIdle) {
			setShowStreamMessages(true);
			setQuestion("");
			mutationStream.mutate({ question: "", chosenCoreValueId: id });
		}
	}, [coreValue?.messages]);

	useEffect(() => {
		messagesEnd.current?.scrollIntoView();
	}, [streamedResponse, coreValue?.messages]);

	const form = useZodForm({
		defaultValues: {
			message: "",
		},
		onSubmit: (data) => {
			setQuestion(data.value.message);
			setShowStreamMessages(true);
			mutationStream.mutate({ question: data.value.message, chosenCoreValueId: id });
			form.reset();
		},
	});

	const unansweredProposal = coreValue?.messages.find(
		(msg) => msg.messageRole == "PROPOSAL" && msg.refineProposal.answered == false,
	);

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary">
						<IonBackButton
							defaultHref={Routes.CoreValues()}
							color="light"
							className="touch-target min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
					<IonTitle>
						{coreValue?.coreValue.emoji} {coreValue?.customName || coreValue?.coreValue.name}
					</IonTitle>
				</Toolbar>
			</IonHeader>
			<Content className="part-scroll:gap-8">
				<div className="flex flex-col gap-4">
					<div className="flex flex-col items-start gap-2 rounded-2xl bg-white p-4">
						<div
							style={{
								backgroundColor: coreValue?.coreValue.color + "55",
							}}
							className="inline-block rounded-full px-2.5 py-1"
						>
							<span className="text-sm font-semibold">
								{coreValue?.coreValue.emoji} {coreValue?.customName || coreValue?.coreValue.name}
							</span>
						</div>
						<p>{coreValue?.description || coreValue?.reason}</p>
					</div>
					{[...(coreValue?.messages || [])].map(
						(message, i) =>
							message && (
								<>
									{message.messageRole == "ASSISTANT" && message.content && (
										<ReflectionChatBubble key={i} sender="bot">
											{message.content}
										</ReflectionChatBubble>
									)}
									{message.messageRole == "USER" && (
										<ReflectionChatBubble key={i} sender="user">
											{message.content}
										</ReflectionChatBubble>
									)}
									{message.messageRole == "PROPOSAL" && message.refineProposal && (
										<div className="relative max-w-[90%]">
											<div className="absolute bottom-0 size-3 rounded-full bg-brown-300" />
											<div className="relative ml-3 flex w-fit flex-col gap-2 rounded-2xl bg-brown-300 p-2 text-brown-700">
												{t("page.refine-core-value.confirm")}
												<div className="w flex flex-col gap-2 rounded-lg bg-brown-200 p-2">
													<div
														style={{
															backgroundColor: coreValue?.coreValue.color + "55",
														}}
														className="inline rounded-full px-2.5 py-1"
													>
														<span className="text-sm font-semibold">
															{coreValue?.coreValue.emoji} {message.refineProposal.name}
														</span>
													</div>
													<p>{message.refineProposal.description}</p>
												</div>
												{!message.refineProposal.answered && (
													<div className="grid grid-cols-2 gap-1">
														<Button
															fill="clear"
															color={"secondary"}
															onClick={async () => {
																if (!coreValue) {
																	return;
																}
																await backendService.corevalues.coreValuesControllerDeclineRefineProposal(
																	coreValue.id,
																);
																setQuestion("");
																setShowStreamMessages(true);
																mutationStream.mutate({
																	question: "",
																	chosenCoreValueId: id,
																});
															}}
														>
															{t("page.refine-core-value.no")}
														</Button>
														<Button
															onClick={async () => {
																if (!coreValue) {
																	return;
																}
																await backendService.corevalues.coreValuesControllerAcceptRefineProposal(
																	coreValue.id,
																);
																setQuestion("");
																setShowStreamMessages(true);
																mutationStream.mutate({
																	question: "",
																	chosenCoreValueId: id,
																});
															}}
														>
															{t("page.refine-core-value.yes")}
														</Button>
													</div>
												)}
											</div>
										</div>
									)}
								</>
							),
					)}
					{showStreamMessages && question !== "" && (
						<ReflectionChatBubble sender="user">{question}</ReflectionChatBubble>
					)}
					{showStreamMessages && streamedResponse != "" && (
						<>
							<ReflectionChatBubble sender="bot">{streamedResponse}</ReflectionChatBubble>
						</>
					)}
					<div ref={messagesEnd} />
				</div>
			</Content>
			<IonFooter>
				<div className="p-4">
					<form
						onSubmit={(event) => {
							event.preventDefault();
							event.stopPropagation();
							form.validateAllFields("submit");
							void form.handleSubmit();
						}}
						className="flex items-center rounded-2xl bg-brown-100 pr-2"
					>
						<form.Field
							name="message"
							children={(field) => (
								<FormInput type="text" placeholder={t("chat.placeholder")} field={field} />
							)}
						/>

						<form.Subscribe
							selector={(state) => [state.isSubmitting]}
							children={([isSubmitting]) => (
								<IonButton disabled={isSubmitting || !!unansweredProposal} type="submit">
									<IonIcon slot="icon-only" icon={paperAirplaneIcon}></IonIcon>
								</IonButton>
							)}
						/>
					</form>
				</div>
			</IonFooter>
		</IonPage>
	);
}
