import { QueryClientProvider, type QueryClientProviderProps } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { debugValue } from "@/env/utils";
import { queryClient } from "@/lib/query";

export function QueryProvider(
	props: React.PropsWithChildren<Omit<QueryClientProviderProps, "client">>,
) {
	return (
		<QueryClientProvider client={queryClient} {...props}>
			{props.children}
			{debugValue("query") && <ReactQueryDevtools />}
		</QueryClientProvider>
	);
}
