import {
	type ActionPerformed,
	PushNotifications as CapacitorPushNotifications,
	type Channel,
	type DeliveredNotifications,
	type PushNotificationSchema,
	type RegistrationError,
	type Token,
} from "@capacitor/push-notifications";
import { Capacitor } from "@/lib/capacitor";
import { logger } from "@/logger";

export const PushNotifications = (function () {
	const PushNotificationsLogger = logger.getLogger("@capacitor/push-notifications");

	async function register() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return await CapacitorPushNotifications.register();
	}

	async function unregister() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return await CapacitorPushNotifications.unregister();
	}

	async function getDeliveredNotifications() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return (await CapacitorPushNotifications.getDeliveredNotifications()).notifications;
	}

	async function removeDeliveredNotifications(
		notifications: DeliveredNotifications["notifications"],
	) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return await CapacitorPushNotifications.removeDeliveredNotifications({ notifications });
	}

	async function removeAllDeliveredNotifications() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return await CapacitorPushNotifications.removeAllDeliveredNotifications();
	}

	async function createChannel(channel: Channel) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		if (Capacitor.Platform !== "android") {
			PushNotificationsLogger.trace(
				"PushNotifications.createChannel() is only available on Android",
			);
			return;
		}

		return await CapacitorPushNotifications.createChannel(channel);
	}

	async function deleteChannel(id: Channel["id"]) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		if (Capacitor.Platform !== "android") {
			PushNotificationsLogger.trace(
				"PushNotifications.createChannel() is only available on Android",
			);
			return;
		}

		return await CapacitorPushNotifications.deleteChannel({ id });
	}

	async function listChannels() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		if (Capacitor.Platform !== "android") {
			PushNotificationsLogger.trace(
				"PushNotifications.createChannel() is only available on Android",
			);
			return;
		}

		return (await CapacitorPushNotifications.listChannels()).channels;
	}

	async function checkPermissions() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return (await CapacitorPushNotifications.checkPermissions()).receive;
	}

	async function requestPermissions() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return (await CapacitorPushNotifications.requestPermissions()).receive;
	}

	function addRegistrationListener(listenerFunc: (token: Token["value"]) => void) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return CapacitorPushNotifications.addListener("registration", ({ value }) =>
			listenerFunc(value),
		);
	}

	function addRegistrationErrorListener(listenerFunc: (error: RegistrationError["error"]) => void) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return CapacitorPushNotifications.addListener("registrationError", ({ error }) =>
			listenerFunc(error),
		);
	}

	function addPushNotificationReceivedListener(
		listenerFunc: (notification: PushNotificationSchema) => void,
	) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return CapacitorPushNotifications.addListener("pushNotificationReceived", listenerFunc);
	}

	function addPushNotificationActionPerformedListener(
		listenerFunc: (notification: ActionPerformed) => void,
	) {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return CapacitorPushNotifications.addListener("pushNotificationActionPerformed", listenerFunc);
	}

	function removeAllListeners() {
		if (Capacitor.Platform === "web") {
			PushNotificationsLogger.trace("PushNotifications is not implemented on web");
			return;
		}

		return CapacitorPushNotifications.removeAllListeners();
	}

	return {
		...CapacitorPushNotifications,
		register,
		unregister,
		getDeliveredNotifications,
		removeDeliveredNotifications,
		removeAllDeliveredNotifications,
		createChannel,
		deleteChannel,
		listChannels,
		checkPermissions,
		requestPermissions,
		addRegistrationListener,
		addRegistrationErrorListener,
		addPushNotificationReceivedListener,
		addPushNotificationActionPerformedListener,
		removeAllListeners,
	};
})();

export type * from "@capacitor/push-notifications";
