import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@/lib/capacitor/App";
import { logger } from "@/logger";

export function AppUrlListener() {
	const history = useHistory();

	useEffect(() => {
		App.addUrlOpenListener((event) => {
			logger.debug(event, "Open App Url");

			const url = new URL(event.url);

			if (url.pathname) {
				history.push({
					pathname: url.pathname,
					search: new URLSearchParams(url.search).toString(),
					hash: url.hash,
				});
			}
			// If no match, do nothing - let regular routing
			// logic take over
		});

		return () => {
			App.removeAllListeners();
		};
	}, []);

	return null;
}
