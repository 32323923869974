import { cx } from "@/lib/style/cva.config";

export function ReflectionChatBubble({
	sender,
	parts,
	...props
}: React.ComponentPropsWithoutRef<"div"> & {
	sender: "user" | "bot";
	parts?: {
		smallBubble?: React.ComponentPropsWithoutRef<"div">;
		text?: React.ComponentPropsWithoutRef<"div">;
	};
}) {
	return (
		<div
			{...props}
			data-attr="chat-bubble"
			className={cx(
				"relative w-fit max-w-[90%] rounded-2xl !bg-[--background] px-3 py-2 text-brown-700",
				sender === "user" && "me-3 ms-auto ion-bg-brown-100",
				sender === "bot" && "me-auto ms-3 ion-bg-brown-300",
				props.className,
			)}
		>
			<div
				{...parts?.smallBubble}
				className={cx(
					"absolute bottom-0 size-3 rounded-full bg-[--background]",
					sender === "user" && "-right-3",
					sender === "bot" && "-left-3",
					parts?.smallBubble?.className,
				)}
			/>
			<p {...parts?.text} className={cx("text-base", parts?.text?.className)}>
				{props.children}
			</p>
		</div>
	);
}
