import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Capacitor } from "@/lib/capacitor";
import { LocalNotifications } from "@/lib/capacitor/LocalNotifications";
import { useMe } from "@/lib/query/functions/user/me";
import { logger } from "@/logger";

export function NotificationListenerLocal() {
	const isWeb = Capacitor.Platform === "web";

	const queryPermissionState = useQuery({
		queryKey: ["local-notification", "permission"],
		queryFn: LocalNotifications.checkPermissions,
		initialData: "prompt" satisfies PermissionState as PermissionState,
		enabled: !isWeb,
	});
	const permissionState = queryPermissionState.data;

	const [me] = useMe();
	const settings = me?.settings;

	useEffect(() => {
		LocalNotifications.addLocalNotificationReceivedListener((notification) =>
			logger.info({ notification }, "Local Notification Received"),
		);

		LocalNotifications.addLocalNotificationActionPerformedListener((notification) => {
			logger.info({ notification }, "Local Notification Action Performed");
		});

		return () => {
			LocalNotifications.removeAllListeners();
		};
	}, []);

	if (
		// Platform is not web
		isWeb ||
		// Settings have loaded
		!settings ||
		// Permission to set local notifications
		permissionState !== "granted"
	) {
		return null;
	}

	return null;
}
