import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { debugValue } from "@/env/utils";
import { DEFAULT_LANGUAGE, i18n } from "@/i18n";
import { Capacitor } from "@/lib/capacitor";
import { Device } from "@/lib/capacitor/Device";
import { Keyboard } from "@/lib/capacitor/Keyboard";
import { MATCH_PREFERS_COLOR_SCHEME_DARK } from "@/lib/constants";
import { createSelectors } from "@/lib/store/utils/createSelectors";
import { CapacitorStorage } from "@/lib/store/utils/storage";

export type PreferencesState = {
	language: "system" | Language;
	colorScheme: "system" | ColorScheme;
	reducedHaptics: boolean;
};

export type PreferencesActions = {
	setLanguage: (language: PreferencesState["language"]) => void;
	setColorScheme: (colorScheme: PreferencesState["colorScheme"]) => void;
	setReducedHaptics: (reducedHaptics: PreferencesState["reducedHaptics"]) => void;
};

export const usePreferencesStore = create<PreferencesState & PreferencesActions>()(
	devtools(
		persist(
			(set) => ({
				// State
				language: "system",
				colorScheme: "light",
				reducedHaptics: false,

				// Actions
				setLanguage: (language) => {
					set({ language });
					enforceLanguage(language);
				},
				setColorScheme: (colorScheme) => {
					set({ colorScheme });
					enforceColorScheme(colorScheme);
				},
				setReducedHaptics: (reducedHaptics) => {
					set({ reducedHaptics });
				},
			}),
			{
				name: "preferences",
				storage: createJSONStorage(() => CapacitorStorage),
				onRehydrateStorage: () => (state) => {
					if (state?.language) {
						state.setLanguage(state?.language);
					}
					if (state?.colorScheme) {
						state.setColorScheme(state.colorScheme);
					}
					if (state?.reducedHaptics) {
						state.setReducedHaptics(state.reducedHaptics);
					}
				},
			},
		),
		{
			enabled: debugValue("store") && Capacitor.Platform === "web",
		},
	),
);

export const useSelectorPreferencesStore = createSelectors(usePreferencesStore);

export async function getComputedLanguage(
	language: PreferencesState["language"],
): Promise<Language> {
	switch (language) {
		case "system":
			return Device.getLanguageCode().then(({ value }) =>
				i18n.languages.includes(value) ? (value as Language) : DEFAULT_LANGUAGE,
			);
		default:
			return language;
	}
}

export async function enforceLanguage(language: PreferencesState["language"]) {
	switch (language) {
		case "system":
			Device.getLanguageCode().then(({ value }) => {
				enforceLanguage(i18n.languages.includes(value) ? (value as Language) : DEFAULT_LANGUAGE);
			});
			break;
		default:
			i18n.changeLanguage(language);
			break;
	}
}

export function getComputedColorScheme(colorScheme: PreferencesState["colorScheme"]): ColorScheme {
	switch (colorScheme) {
		case "system":
			return window.matchMedia(MATCH_PREFERS_COLOR_SCHEME_DARK).matches ? "dark" : "light";
		default:
			return colorScheme;
	}
}

export function enforceColorScheme(colorScheme: PreferencesState["colorScheme"]) {
	switch (colorScheme) {
		case "system":
			enforceColorScheme(
				window.matchMedia(MATCH_PREFERS_COLOR_SCHEME_DARK).matches ? "dark" : "light",
			);
			break;
		default:
			switch (colorScheme) {
				case "dark":
					Keyboard.setStyle(Keyboard.Style.Dark);
					document.documentElement.classList.add("dark");
					break;
				case "light":
				default:
					Keyboard.setStyle(Keyboard.Style.Light);
					document.documentElement.classList.remove("dark");
					break;
			}
			break;
	}
}
