import { IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { FriendCheckCard } from "@/components/FriendCheck/Card";
import { type FriendCheckSubmission } from "@/lib/backend/api";
import { briefcaseIcon, homeIcon, userGroupIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { cx } from "@/lib/style/cva.config";

export function FriendCheckFilledCard({
	data,
	withUnseen = false,
	...props
}: React.ComponentProps<typeof FriendCheckCard> & {
	data: FriendCheckSubmission;
	withUnseen?: boolean;
}) {
	const { t, i18n } = useTranslation();

	return (
		<FriendCheckCard {...props} className={cx("[contain:unset] [overflow:unset]", props.className)}>
			<div className="flex justify-between gap-2">
				<strong data-ph-no-capture className="truncate text-lg font-bold text-brown-700">
					{data.name}
				</strong>

				<div className="flex items-center justify-end gap-1 text-base font-semibold text-brown-500">
					<IonIcon
						icon={(function () {
							switch (data.relation.toLowerCase()) {
								case "collega":
								case "colleague":
									return briefcaseIcon;
								case "familie":
								case "family":
									return homeIcon;
								case "vriend":
								case "friend":
								case "other":
								default:
									return userGroupIcon;
							}
						})()}
						className="size-5"
					/>
					<span>
						{(function () {
							switch (data.relation.toLowerCase()) {
								case "collega":
								case "colleague":
									return t("page.shared-friend-check.fields.relation.options.colleague");
								case "familie":
								case "family":
									return t("page.shared-friend-check.fields.relation.options.family");
								case "vriend":
								case "friend":
									return t("page.shared-friend-check.fields.relation.options.friend");
								case "other":
								default:
									return t("page.shared-friend-check.fields.relation.options.other");
							}
						})()}
					</span>
				</div>
			</div>
			{!!data.createdAt && (
				<span className="w-full text-start text-xs font-medium text-brown-400">
					{t("friend-check.card.received-at", {
						dateTime: Intl.DateTimeFormat(i18n.language, {
							dateStyle: "long",
							timeStyle: "short",
						}).format(new Date(data.createdAt)),
					})}
				</span>
			)}
			{withUnseen && !data.seen && (
				<div
					aria-hidden={data.seen}
					className="pointer-events-none absolute right-0 top-0 size-2.5 opacity-100 transition-opacity *:size-full *:rounded-full *:bg-orange-500 aria-hidden:opacity-0"
				>
					<div className="absolute animate-ping" />
					<div className="relative" />
				</div>
			)}
		</FriendCheckCard>
	);
}
