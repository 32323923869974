import { useEffect } from "react";
import { PostHogProvider as Provider } from "posthog-js/react";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils";
import { getFullName } from "@/lib/getFullName";
import { posthog } from "@/lib/posthog";
import { useMe } from "@/lib/query/functions/user/me";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export function PostHogProvider(props: React.ComponentProps<typeof Provider>) {
	const isAuthenticated = useAuthStore(authSelectors.isAuthenticated);
	const [me] = useMe();
	const queryAccess = useExtractedQuery({
		queryKey: ["user", "me", "access"],
		fn: (context) => backendService.user.usersControllerGetMyAccess({ signal: context.signal }),
		enabled: isAuthenticated,
	});
	const [myProgression] = useMyProgression();

	useEffect(() => {
		if (!me) {
			return;
		}

		posthog.identify(me.id, {
			email: me.email,
			emailVerified: me.emailVerified,
			name: getFullName(me),
			image: me.avatarURL,
			authSource: me.authSource,
			access: queryAccess.data?.hasAccess,
			progression_reflection: myProgression.reflection,
			progression_friend_check: myProgression.friend_check,
			progression_core_values: myProgression.core_values,
		});
	}, [me, queryAccess.data?.hasAccess, myProgression]);

	return <Provider client={posthog} {...props} />;
}
