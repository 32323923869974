import * as SentryCapacitor from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { env } from "@/env";
import { debugValue } from "@/env/utils";
import { history } from "@/lib/router/history";

SentryCapacitor.init(
	{
		dsn: env.VITE_SENTRY_DSN,
		environment: env.VITE_SENTRY_ENVIRONMENT,
		release: __SENTRY_RELEASE__,

		integrations: [
			SentryReact.browserTracingIntegration(),
			SentryReact.reactRouterV5BrowserTracingIntegration({
				history: history as unknown as Parameters<
					(typeof SentryReact)["reactRouterV5BrowserTracingIntegration"]
				>[0]["history"],
			}),
			SentryReact.replayIntegration({
				networkDetailAllowUrls: [
					"localhost",
					window.location.origin,
					env.VITE_BACKEND_URL,
					"imperfect.so",
					"stijlbre.uk",
					"stagi.nl",
				],
			}),
		],

		tracesSampleRate: env.VITE_SENTRY_TRACES_SAMPLE_RATE,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			window.location.origin,
			env.VITE_BACKEND_URL,
			"imperfect.so",
			"stijlbre.uk",
			"stagi.nl",
		],

		replaysSessionSampleRate: env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
		replaysOnErrorSampleRate: env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE,

		debug: debugValue("sentry"),
	},
	SentryReact.init,
);

export { SentryCapacitor, SentryReact };
