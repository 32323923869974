import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { debugValue } from "@/env/utils";
import { backendService } from "@/lib/backend";
import { Capacitor } from "@/lib/capacitor";
import { LocalNotifications } from "@/lib/capacitor/LocalNotifications";
import { PushNotifications } from "@/lib/capacitor/PushNotifications";
import { SentryCapacitor } from "@/lib/sentry";
import { createSelectors } from "@/lib/store/utils/createSelectors";
import { CapacitorStorage } from "@/lib/store/utils/storage";

export type AuthState = {
	accessToken?: string;
	refreshToken?: string;
};

export type AuthActions = {
	authenticate: (tokens: { accessToken: string; refreshToken?: string }) => void;
	unauthenticate: () => void;
};

const initialState: AuthState = {
	accessToken: undefined,
	refreshToken: undefined,
};

export const useAuthStore = create<AuthState & AuthActions>()(
	devtools(
		persist(
			(set) => ({
				...initialState,

				// Actions
				authenticate: ({ accessToken, refreshToken }) => {
					set({ accessToken, refreshToken });
					backendService.setSecurityData({ accessToken });
				},
				unauthenticate: () => {
					set(initialState);
					backendService.setSecurityData(null);
					SentryCapacitor.setUser(null);

					Promise.all([
						PushNotifications.unregister(),
						LocalNotifications.getPending().then((notifications) =>
							notifications.length > 0 ? LocalNotifications.cancel(notifications) : undefined,
						),
					]).then(() => window.location.reload());
				},
			}),
			{
				name: "auth",
				storage: createJSONStorage(() => CapacitorStorage),
				onRehydrateStorage: () => (state) => {
					if (state?.accessToken && state.refreshToken) {
						state.authenticate({
							accessToken: state.accessToken,
							refreshToken: state.refreshToken,
						});
					}
				},
			},
		),
		{
			enabled: debugValue("store") && Capacitor.Platform === "web",
		},
	),
);

export const useSelectorAuthStore = createSelectors(useAuthStore);

export const authSelectors = {
	isAuthenticated: (state: AuthState) => !!state.accessToken || !!state.refreshToken,
};
